<template>
  <div class="tableList">
    <div class="search">
      <div class="search-body">
        <a-form-model layout="inline">
          <a-form-model-item label="标题">
            <a-input placeholder="请输入" v-model="search.title" />
          </a-form-model-item>
          <a-form-item label="添加时间">
            <a-range-picker @change="onDateChange" />
          </a-form-item>
          <a-form-model-item>
            <p-button type="primary" @click="searchList" name="搜索" />
          </a-form-model-item>
        </a-form-model>
        <div class="button_group">
          <p-button name="添加" @click="showInfo(0)" type="primary" />
          <span style="margin-left: 8px">
            <template v-if="selectRows.length > 0">
              {{ `已选择 ${selectRows.length} 项` }}
              <a-button type="link" @click="cancelSelect">取消选择</a-button>
            </template>
          </span>
        </div>
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: 1000, y: list.tableHeight }"
      >
        <template slot="articleType" slot-scope="articleType">
          <span v-if="articleType == 1">文章</span>
          <span v-if="articleType == 2">视频</span>
          <span v-if="articleType == 3">图集</span>
        </template>
        <template slot="pictureType" slot-scope="pictureType">
          <span v-if="pictureType == 0">无图</span>
          <span v-if="pictureType == 1">单图大图</span>
          <span v-if="pictureType == 2">单图居左</span>
          <span v-if="pictureType == 3">单图居右</span>
          <span v-if="pictureType == 4">三图</span>
        </template>
        <template slot="recommend" slot-scope="recommend">
          <span v-if="recommend == 1">推荐</span>
        </template>
        <!-- <template slot="auditStatus" slot-scope="auditStatus">
              <span v-if="auditStatus == 0">待提交</span>
              <span v-if="auditStatus == 1">待审核</span>
              <span v-if="auditStatus == 2">审核通过</span>
              <span v-if="auditStatus == 3">审核拒绝</span>
            </template> -->
        <template slot="auditStatus" slot-scope="auditStatus">
          <processStatus :processModel="auditStatus" />
        </template>
        <span slot="action" slot-scope="text, record, index">
          <a-button
            type="primary"
            @click="showInfo(record.id, record, index)"
            size="small"
            >{{
              record.processStatus == null || record.processStatus == 0
                ? "编辑"
                : "查看"
            }}</a-button
          >
          <a-popconfirm @confirm="delInfo(record.id, record, index)">
            <div slot="title">是否确认删除此文章？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <a-button
              name="删除"
              type="danger"
              size="small"
              :roles="['文章-文章删除']"
              v-if="record.processStatus == null || record.processStatus == 0"
              >刪除</a-button
            >
          </a-popconfirm>
        </span>
      </a-table>
    </div>
    <a-modal
      title="详情"
      v-model="addedit.visible"
      width="1200px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <NewsArticleInfo @callback="save" :info="addedit.info" />
    </a-modal>
  </div>
</template>

<script>
import NewsArticleInfo from "./NewsArticleInfo.vue"; //新增编辑
import newsArticle from "@/api/cms/newsArticle";
const columns = [
  {
    dataIndex: "title",
    align: "left",
    title: "标题",
    ellipsis: true,
  },
  {
    dataIndex: "categoryName",
    align: "center",
    title: "所属分类",
    ellipsis: true,
  },
  {
    dataIndex: "articleType",
    align: "center",
    title: "类型",
    scopedSlots: { customRender: "articleType" },
  },
  // {
  //   dataIndex: "pictureType",
  //   align: "center",
  //   title: "封面图类型",
  //   scopedSlots: { customRender: "pictureType" },
  // },
  {
    dataIndex: "recommend",
    align: "center",
    title: "标签",
    scopedSlots: { customRender: "recommend" },
  },
  {
    dataIndex: "publishTime",
    align: "center",
    title: "发布日期",
  },
  {
    dataIndex: "createTime",
    align: "center",
    title: "添加时间",
  },
  {
    align: "center",
    title: "状态",
    scopedSlots: { customRender: "auditStatus" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "StaffAtricleList",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 330,
      },
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
      search: {
        userId: "123",
        categoryName: "职工园地",
        title: "",
        publishTimeStart: null,
        publishTimeEnd: null,
      },
      selectRows: [], //选中项编号
      isloading: false,
    };
  },
  components: {
    NewsArticleInfo,
  },
  mounted() {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 330;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      let queryData = { ..._this.search };
      newsArticle
        .pageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          queryData
        )
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      this.GetList();
    },
    onRowChange(selectedRowKeys) {
      this.selectRows = selectedRowKeys;
    },
    cancelSelect() {
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.cancelSelect();
      _this.GetList();
    },
    /*审核查看 */
    showInfo(id) {
      this.addedit.visible = true;
      this.addedit.info = { id, specialTypeId: 57 };
    },
    submit(id) {
      _this.isloading = true;
      newsArticle
        .flow({ id, publish: true })
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /** 批量提交 */
    batchSubmit() {
      this.$confirm({
        title: "确定要批量提交选择的文章吗?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.submit(_this.selectRows.join());
        },
        onCancel() {},
      });
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      newsArticle
        .removeByIds(id)
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.$message.success(res.errorMsg);
            _this.selectRows = [];
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /** 批量删除 */
    batchDelete() {
      this.$confirm({
        title: "确定要批量删除选择的文章吗?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          _this.delInfo(_this.selectRows.join());
        },
        onCancel() {},
      });
    },
    save(f) {
      this.addedit.visible = false;
      if (f) this.GetList();
    },
    onDateChange(date, dateString) {
      _this.search.publishTimeStart = dateString[0];
      _this.search.publishTimeEnd = dateString[1];
    },
  },
};
</script>

<style>
</style>
